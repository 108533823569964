import { createAction } from '@ngrx/store';

export const pageInitialized = createAction('[App] Page initialized');
export const pageLoggedInInitialized = createAction('[App] Page initialized and user is logged in');
export const homePageInitialized = createAction('[App] Home page initialized');
export const dealsInitialized = createAction('[App] Deals initialized');
export const dealPageInitialized = createAction('[App] Deal page initialized');
export const dealsManagementInitialized = createAction('[App] Deals management initialized');
export const tenantsPageInitialized = createAction('[App] System tenants page initialized');
export const superAdminsPageInitialized = createAction('[App] System super admins page initialized');
export const tenantUsersPageInitialized = createAction('[App] System tenant users page initialized');
export const tenantSinglePageInitialized = createAction('[App] System tenant system page initialized');
export const usersPageInitialized = createAction('[App] Users page initialized');
export const dealsTeamPageInitialized = createAction('[App] Deals team page initialized');
export const dealsLendersListPageInitialized = createAction('[App] Deals lenders list page initialized');
export const dealsSponsorTeamPageInitialized = createAction('[App] Deals sponsor team page initialized');
export const dealsIndicativeTermsPageInitialized = createAction('[App] Deals indicative terms page initialized');
export const dealsSearchInitialized = createAction('[App] Deals search page initialized');
export const dealSearchItemPageInitialized = createAction('[App] Deal search item page initialized');
export const managementCriteriaListInitialized = createAction('[App] Management criteria list initialized');
export const managementCompanyInitialized = createAction('[App] Management company initialized');
export const dealsDataRoomPageInitialized = createAction('[App] Deals data room page initialized');
export const profileSearchPageInitialized = createAction('[App] Profile search page initialized');
export const profileSinglePageInitialized = createAction('[App] Profile single page initialized');
export const profileCreatePageInitialized = createAction('[App] Profile create page initialized');
export const dealsQAPageInitialized = createAction('[App] Deals Q&A page initialized');
export const systemTenantMandatePageInitialized = createAction('[App] System tenant mandate page initialized');
export const systemDealsPageInitialized = createAction('[App] System deals page initialized');
export const systemClosedDealsPageInitialized = createAction('[App] System closed deals page initialized');
export const systemArchivedDealsPageInitialized = createAction('[App] System archived deals page initialized');
export const profileContactInitialized = createAction('[App] Profile contact initialized');
export const systemNewsListPageInitiated = createAction('[App] Sytem news list page initialized');
export const systemNewsEditPageInitialized = createAction('[App] Sytem news edit page initialized');

export const InitActions = {
  pageInitialized,
  pageLoggedInInitialized,
  homePageInitialized,
  dealsInitialized,
  dealsTeamPageInitialized,
  dealsLendersListPageInitialized,
  dealsManagementInitialized,
  dealsSearchInitialized,
  dealSearchItemPageInitialized,
  dealsSponsorTeamPageInitialized,
  dealsIndicativeTermsPageInitialized,
  dealsDataRoomPageInitialized,
  dealsQAPageInitialized,
  tenantsPageInitialized,
  tenantSinglePageInitialized,
  usersPageInitialized,
  managementCriteriaListInitialized,
  managementCompanyInitialized,
  profileSearchPageInitialized,
  profileSinglePageInitialized,
  profileCreatePageInitialized,
  superAdminsPageInitialized,
  tenantUsersPageInitialized,
  systemTenantMandatePageInitialized,
  systemDealsPageInitialized,
  dealPageInitialized,
  systemClosedDealsPageInitialized,
  systemArchivedDealsPageInitialized,
  profileContactInitialized,

  systemNewsListPageInitiated,
  systemNewsEditPageInitialized,
};
